/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum JitsiWebhookEventType {
    PARTICIPANT_LEFT = 'PARTICIPANT_LEFT',
    PARTICIPANT_JOINED = 'PARTICIPANT_JOINED',
    ROOM_CREATED = 'ROOM_CREATED',
    ROOM_DESTROYED = 'ROOM_DESTROYED',
    LIVE_STREAM_STARTED = 'LIVE_STREAM_STARTED',
    LIVE_STREAM_ENDED = 'LIVE_STREAM_ENDED',
    RECORDING_UPLOADED = 'RECORDING_UPLOADED',
    TRANSCRIPTION_UPLOADED = 'TRANSCRIPTION_UPLOADED',
}