import React, { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';

import {
	AccountService,
	AdminService,
	PermissionsListResponse,
	WebsocketEventName,
} from 'clients/CoreService';
import { useConfirmDialog } from 'components/ConfirmDialog';
import { ApiToken, useApiToken } from 'hooks/auth/useApiToken';
import { useLogout } from 'hooks/auth/useLogout';
import { useSnackbarOnError } from 'hooks';
import useSocket from 'hooks/socket/useSocket';
import { entities } from 'consts';

import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import { SentryDefaults } from 'index';
import { LogoutHolder } from './PermissionsContext.styles';
import errors from '../../locales/en/errors.json';

export const PermissionsContext = createContext<
	(PermissionsListResponse & { participantId?: string | number }) | null
>(null);

export const usePermissions = () => {
	return useContext(PermissionsContext)?.permissions;
};

export const useRole = () => {
	return useContext(PermissionsContext)?.role;
};

export const useOrganizationId = () => {
	return useContext(PermissionsContext)?.organizationId;
};

export const useParticipantId = (): string | null => {
	const participantId = useContext(PermissionsContext)?.participantId;
	// because in API responses it's always string
	return participantId ? `${participantId}` : null;
};

export const useHasRole = (role: PermissionsListResponse['role']) => {
	return useRole() === role;
};

export const useHasPermissions = (permission: PermissionsListResponse['permissions'][0]) => {
	return usePermissions()?.includes(permission);
};

function isSuspendError(error: any) {
	return ['ForbiddenAccessForSuspendedUserError', 'UnauthorizedError'].includes(
		error?.body?.id || error?.data?.id,
	);
}

function decodeToken(token: ApiToken): any {
	try {
		return JSON.parse(atob(/.*?\.(.*?)\..*?/.exec(token.accessToken)?.[1] || ''));
	} catch {
		return {};
	}
}

const clearStorage = () => {
	const accountResponse = localStorage.getItem('ACCOUNT_RESPONSE');
	if (accountResponse) {
		const parsedResponse = JSON.parse(accountResponse);
		if (parsedResponse.meetingId) {
			localStorage.removeItem('ACCOUNT_RESPONSE');
			console.log('ACCOUNT_RESPONSE cleared from storage');
		}
	}
};

export const PermissionContextProvider: FC = ({ children }) => {
	const logout = useLogout();
	const [apiToken] = useApiToken();
	const onError = useSnackbarOnError();
	const confirmDialog = useConfirmDialog();
	const queryClient = useQueryClient();

	useEffect(() => {
		if (apiToken) {
			try {
				SentryDefaults.user = {
					...SentryDefaults.user,
					email: decodeToken(apiToken).login,
				};
			} catch {}
		}
	}, [apiToken]);

	const { data, error } = useQuery(
		[entities.permissions, apiToken],
		() => {
			if (apiToken && apiToken.accessToken) {
				return AccountService.getPermissions();
			}
			return Promise.reject(new Error('EmptyAuthorizationTokenError'));
		},
		{
			enabled: !!apiToken && !!apiToken.accessToken,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			onError: (error: any) => {
				if (!isSuspendError(error)) {
					onError(error);
				}
			},
			retry: (attempt, error) => {
				if (attempt < 3 && !isSuspendError(error)) {
					const userDoesNotExistError = errors.UserDoesNotExistForProvidedTokenError;
					if (apiToken) {
						const isAdmin =
							decodeToken(apiToken)?.userRole === 'PlatformAdmin' ||
							decodeToken(apiToken)?.userRole === 'OrganizationAdmin';
						if (!isAdmin && error.body.message === userDoesNotExistError) {
							clearStorage();
							window.location.reload();
						}
					}
					return true;
				}
				return false;
			},
		},
	);

	useSocket([
		{
			type: WebsocketEventName.ATTENDEE_ROLE_UPDATED,
			listener: () => queryClient.invalidateQueries(entities.permissions),
		},
	]);

	const providerData = useMemo(() => {
		return apiToken && data
			? {
					...data,
					...{ participantId: decodeToken(apiToken).participantId || undefined },
			  }
			: null;
	}, [data, apiToken]);

	const isSuspended = data?.userStatus === 'Suspend' || isSuspendError(error);

	const { data: adminMe } = useQuery(
		[entities.administrators, entities.administratorsMe],
		() => AdminService.getAdmin(),
		{ onError, enabled: isSuspended },
	);

	useEffect(() => {
		if (isSuspended) {
			confirmDialog(
				{
					type: 'info',
					bigTitle: 'Sorry, you have been deleted',
					icon: DangerIcon,
					persistent: true,
					description: (
						<>
							If you want to reset your account, please contact us via email{' '}
							<a
								href={'mailto:support@motionmeetings.co'}
								target={'_blank'}
								rel={'noopener noreferrer'}
								style={{ textDecoration: 'none' }}
							>
								support@motionmeetings.co{' '}
							</a>
							and we will help you
						</>
					),
					confirmText: 'Logout',
				},
				true,
			).then(logout);
		}
	}, [isSuspended, adminMe, confirmDialog, logout]);

	if (isSuspended) {
		return null;
	}

	return apiToken && !data ? (
		<>
			<LinearProgress />
			{/* in case of infinite loading after login */}
			<LogoutHolder>
				<Button onClick={logout}>Logout</Button>
			</LogoutHolder>
		</>
	) : (
		<PermissionsContext.Provider value={providerData}>{children}</PermissionsContext.Provider>
	);
};
